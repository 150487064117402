export default {
  name: 'Pagination',
  inheritAttrs: false,
  props: {
    pageNo: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageTotal: {
      required: true,
      type: Number
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 40, 50, 100];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    }
  },
  data() {
    return {
      state: true // 解决size改变，current不够触发了两次请求的问题
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.pageNo;
      },
      set(val) {
        this.$emit('update:pageNo', val);
      }
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit('update:limit', val);
      }
    }
  },
  methods: {
    // 页码选择
    currentChange(val) {
      if (this.state === true) {
        this.$emit('changePagination', {
          currentPage: val,
          pageSize: this.pageSize
        });
      }
    },
    // 每页显示数据量变更
    sizeChange(val) {
      this.state = false;
      let {
        currentPage
      } = this;
      if (val * currentPage > this.pageTotal) {
        // 重置到第一页
        currentPage = 1;
      }
      if (this.state === false) {
        this.$emit('changePagination', {
          currentPage,
          pageSize: val
        });
        this.state = true;
      }
    }
  }
};