import PersonalDetail from '@/components/payslipDetail/PersonalDetail';
export default {
  name: 'SalaryDetail',
  components: {
    PersonalDetail
  },
  props: {
    salaryDetailVisible: {
      type: Boolean,
      default: false
    },
    basicData: {
      type: Object,
      default: () => {}
    },
    salaryData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleCloseFn() {
      this.$emit('update:salaryDetailVisible', false);
    }
  }
};