// 确认状态
export const confirmStatusList = [{
  id: '0',
  name: '未确认'
}, {
  id: '1',
  name: '已确认'
}, {
  id: '2',
  name: '存在问题'
}];

// 发送状态
export const sendStatusList = [{
  id: '0',
  name: '未发送'
}, {
  id: '1',
  name: '已发送'
}];