var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      title: "工资详情",
      visible: _vm.salaryDetailVisible,
      width: "400px",
      center: "",
      "before-close": _vm.handleCloseFn,
      "close-on-click-modal": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.salaryDetailVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "salary-detail-con"
  }, [_c("PersonalDetail", {
    attrs: {
      "basic-data": _vm.basicData,
      "salary-data": _vm.salaryData
    }
  })], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };