import "core-js/modules/es.array.reduce.js";
import { formatPayslipDetail } from '@/views/payslip/util/compileData';
import Pagination from '@/components/pagination/Pagination';
import SalaryDetail from '@/components/salaryDetailList/SalaryDetail';
import { salaryDetailGetSalaryDetailPageList, salaryDetailInfo } from '@/api/salary';
import { formatChinaTime } from '@/utils/util';
import { confirmStatusList, sendStatusList } from './util/constantsData';
export default {
  name: 'SalaryDetailList',
  components: {
    Pagination,
    SalaryDetail
  },
  data() {
    return {
      formInline: {
        name: '',
        mobile: '',
        confirmStatus: '',
        sendStatus: ''
      },
      confirmStatusList,
      // 确认状态
      sendStatusList,
      // 发送状态
      uploadId: '',
      tableData: [],
      pages: {
        currentPage: 1,
        // 当前页码
        pageTotal: 0,
        // 数据总条目
        pageSize: 50 // 每页显示多少条数据
      },
      salaryDetailVisible: false,
      // 工资详情弹窗是否显示
      year: '',
      month: '',
      basicData: {},
      // 基本数据
      salaryData: [] // 工资明细数据
    };
  },
  computed: {
    title() {
      return `工资表明细（${this.year}年${this.month}月）`;
    }
  },
  created() {
    const {
      uploadId,
      year,
      month
    } = this.$route.query;
    this.uploadId = uploadId || '';
    this.year = year || '';
    this.month = month || '';
    if (uploadId) {
      this.fetchDataFn(this.pages);
    }
  },
  methods: {
    indexMethod(index) {
      const {
        currentPage,
        pageSize
      } = this.pages;
      return index + 1 + (currentPage - 1) * pageSize;
    },
    goBackFn() {
      this.$router.go(-1);
    },
    formatSendTimeFn({
      sendTime
    }) {
      return formatChinaTime(sendTime, 'yyyy-MM-dd HH:mm:ss');
    },
    // 请求工资表格分页数据
    async fetchDataFn(pages) {
      const {
        currentPage: pageNo,
        pageSize
      } = pages;
      const params = {
        pageNo,
        pageSize,
        uploadRecordId: this.uploadId,
        ...this.formInline
      };
      const res = await salaryDetailGetSalaryDetailPageList(params);
      if (res !== null && res !== void 0 && res.data) {
        const {
          items = [],
          total = '0'
        } = res.data;
        this.tableData = items;
        this.pages.pageTotal = Number(total);
      }
    },
    // 分页改变
    handleChangePageFn(val) {
      this.$refs.tableRef.bodyWrapper.scrollTop = 0;
      this.pages = {
        ...this.pages,
        ...val
      };
      this.fetchDataFn(this.pages);
    },
    handleSearch() {
      this.pages.currentPage = 1;
      this.fetchDataFn(this.pages);
    },
    handleReset(name) {
      this.$refs[name].resetFields();
      this.resetPagination();
      this.fetchDataFn(this.pages);
    },
    // 重置分页
    resetPagination() {
      this.pages = {
        ...this.pages,
        currentPage: 1,
        pageSize: 50
      };
    },
    // 跳转到核对工资表页面
    goCheckPageFn(row) {
      this.fetchSalaryDetailFn(row.id);
    },
    // 请求工资明细数据
    async fetchSalaryDetailFn(id) {
      const res = await salaryDetailInfo({
        id
      });
      if (res !== null && res !== void 0 && res.data) {
        const result = res === null || res === void 0 ? void 0 : res.data;
        const noUseKeyList = ['id', 'confirmStatus', 'userId', 'sendTime', 'uploadRecordId', 'sendStatus'];
        const rest = Object.keys(result).reduce((acc, cur) => {
          if (!noUseKeyList.includes(cur)) {
            acc[cur] = result[cur];
          }
          return acc;
        }, {});
        this.handleDetailDataFn(rest);
      }
    },
    // 处理详情数据
    handleDetailDataFn(salaryData) {
      const {
        basicData,
        detailData
      } = formatPayslipDetail(salaryData);
      this.basicData = basicData;
      this.salaryData = detailData;
      this.showSalaryDetailDialogFn();
    },
    showSalaryDetailDialogFn() {
      this.salaryDetailVisible = true;
    }
  }
};