var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pagination"
  }, [_c("el-pagination", _vm._b({
    attrs: {
      background: "",
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      total: _vm.pageTotal,
      "page-sizes": _vm.pageSizes,
      layout: _vm.layout
    },
    on: {
      "update:currentPage": function ($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function ($event) {
        _vm.currentPage = $event;
      },
      "update:pageSize": function ($event) {
        _vm.pageSize = $event;
      },
      "update:page-size": function ($event) {
        _vm.pageSize = $event;
      },
      "current-change": _vm.currentChange,
      "size-change": _vm.sizeChange
    }
  }, "el-pagination", _vm.$attrs, false))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };